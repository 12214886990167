<template>
  <div class="dashboard">
    <div class="row">
      <div class="col-md-5">
      </div>
      <div class="col-md-7">
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'dashboard',
    components:{
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      result(){
        return this.$store.state.dashboardData
      }
    },
    created(){
      this.$http.get('/analytics')
      .then((response) => {
        if(response.data.success){
          this.$store.commit('UPDATE_DASHBOARD', response.data.data)
        }
      })
    }
}
</script>

